import Modals from './Modals'

const ModalStore = {
    state: [],
    defaults: {
        // overlap: false,
        // closeOnClick: true,
        // showClose: true,
    },

    setOptions(options) {
        this.defaults = Object.assign(this.defaults, options);
    },

    addModal( res ){
        res.timestamp = new Date();
        res.timestamp.setMilliseconds(
            res.timestamp.getMilliseconds() + this.state.length
        );

        res = Object.assign({}, this.defaults, res);
        this.state.push(res);
    },

    removeModal(timestamp) {
      const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp);

      if (indexToDelete !== -1) {
        this.state.splice(indexToDelete, 1);
      }
    },

    create ( data ){

        return new Promise((resolve, reject) => {

            if (Array.isArray(data)) {
                data.forEach(instance => {
                  this.addModal(instance);
                });

            } else {
                this.addModal(data);
            }
        });
    }
};

const Modal = {

    install(Vue, options) {

        let app = new Vue({
            data: {
                modalStore: ModalStore,
            },
        });

        Vue.prototype.$modal = app.modalStore;

        const MyPartial = Vue.extend({});
        const md = new MyPartial( Modals )
        document.body.appendChild(md.$mount().$el)

        // Vue.component("ModalComponent", ModalComponent);

        if (options) {
            ModalStore.setOptions(options);
        }
    },
};

export default Modal;
