<template>
    <div class="lightbox-wrapper dark" v-if="active">
        <div class="lightbox-backdrop" @click="close"></div>
        <div class="lightbox-section">
            <div class="lightbox-close" @click="close">
                <svg
                    width="24"
                    viewBox="0 0 24 24"
                    role="button"
                    aria-label="close"
                    tabindex="0"
                >
                    <path
                        d="M12 10.586l7.293-7.293 1.414 1.414L13.414 12l7.293 7.293-1.414 1.414L12 13.414l-7.293 7.293-1.414-1.414L10.586 12 3.293 4.707l1.414-1.414L12 10.586z"
                        fill="currentColor"
                    ></path>
                </svg>
            </div>
            <div class="lightbox-content">
                <a
                    class="d-block"
                    :href="href"
                    target="_blank"
                >
                    <img
                        :src="imageUrl"
                        :alt="ariaLabel"
                    />
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: ['imageUrl', 'href', 'ariaLabel'],
    data: () => ({
        active: true,
    }),
    methods: {
        _wrapper() {
            this.close();
        },

        close() {
            this.active = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.lightbox-wrapper {
    position: relative;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 400;
}

.lightbox-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    animation: _1JlhQ 0.65s;

    z-index: 1;
}
.lightbox-wrapper.dark {
    // background-color: rgba(0,0,0,.7);

    .lightbox-backdrop {
        background-color: rgba(0, 0, 0, 0.7);
    }
}
.lightbox-section {
    position: relative;
    z-index: 5;
    animation: _3w02T 0.65s;
}

.lightbox-content {
    position: relative;
    border-radius: 12px;
    -webkit-font-smoothing: antialiased;
    margin: 15px;

    max-width: 728px;
    background-color: #fff;

    overflow: hidden;

    img {
        width: 100%;
    }
}

.lightbox-close {
    position: absolute;
    top: 100%;

    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: #fff;

    left: 50%;
    transform: translateX(-50%);

    &:hover{
background: rgba(0, 0, 0, 0.8 );
    }
}
@keyframes _1JlhQ {
    0% {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes _3w02T {
    0% {
        transform: translateY(3%);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}
</style>
