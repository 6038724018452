<template>
    <div class="modal--main">

        <button v-if="!loading" type="button" class="modal--close" @click="close"><svg width="24" heigth="24" viewBox="0 0 24 24"><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12 19 6.41z"></path></svg></button>

        <!-- <div class="modal--overlay" @click="close"></div> -->

        <div class="modal--layout">
            <div class="middle modal--container">
                <content-render v-if="component" ref="compo" :component="component"></content-render>

                <div class="modal--content" v-else>
                    <div v-if="title"><h3 class="title">{{ title }}</h3></div>
                    <span>modal</span>
                </div>

            </div>
        </div>


        <div class="modal--loading" v-if="loading">
            <div>กำลังโหลด..</div>
        </div>

    </div>
</template>

<script>
export default {

    components: {
        contentRender: {
            props: ['component'],
            render(h) {
                return h(this.component)
            }
        }
    },

    props: {
        message: String,
        title: String,
        timestamp: {
            type: Date,
            default: () => new Date()
        },

        component: {
            type: [Object, Function]
        },

        containerStyle: Object,

        loading: Boolean,

        // componentState: Object,
    },

    data(){
        return {
            // data: {title: 11},
        }
    },

    created(){

        console.log(this.loading);
        // vm.$emit("close", this.loading);

        if( this.component ){

        }

        const $body = $('body');

        let w1 = $body.width();

        document.body.classList.add("modal-open");

        let w2 = $body.width();


        if( w2 > w1 ){
            $body.css('padding-right', w2-w1)
        }

        console.log( 'created model...', this.model );
    },


    methods: {
        close(){
            const $body = $('body');

            console.log( 'model : close...',  )

            if (this.$refs.compo) {

                if( typeof this.$refs.compo.vclose=='function' ){
                    this.$refs.compo.vclose()
                }

                console.log( 'typeof', typeof this.$refs.compo.vclose )
                //
            }


            document.body.classList.remove("modal-open");
            $body.css('padding-right', 0)
            this.$emit("close", this.timestamp);
        }
    }
}
</script>


<style lang="scss">

    .modal--loading{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;

        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
    }
    .modal--main{
        position: fixed;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 500;
        overflow-y: auto;

        /* display: flex;
        justify-content: center;
        align-content: center; */

        background-color: rgba(0,0,0,0.502);
        transition: opacity .15s cubic-bezier(0.4,0.0,0.2,1) .15s;
    }
    .modal--overlay{

        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        transition: opacity .15s cubic-bezier(0.4,0.0,0.2,1) .15s;
        background-color: rgba(0,0,0,0.502);

        /* opacity: 0; */

    }

    .modal--content{
        position: relative;
        margin: 0 auto;
        background-color: #fff;
        /* width: 100%; */
        width: 450px;
    }

    .modal--layout{
        width: 100%;
        height: 100%;
    }



    .modal--close{
        position: fixed;
        top: 15px;
        right: 15px;


        display: flex;
        align-items: center;
        justify-content: center;
        width: 48px;
        height: 48px;
        background: #f3f3f4;
        border-radius: 8px;
    }

    @media (min-width: 576px){
        .modal--layout{
            display: table;

            .middle{
                display: table-cell;
                vertical-align: middle;
            }
        }

    }

    @media (max-width: 575.89px){


        .modal--layout{

            .middle{
                height: 100%;
            }

            .modal-video--container,
            .modal-video{
                height: 100%;
            }
        }
    }


</style>
