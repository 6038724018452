<template>
<!-- top center -->
<!-- bottom left -->
    <div class="toast-container top right" v-if="toasts.length > 0">
        <toast
            v-for="toast in toasts"
            v-bind="toast"
            :key="toast.timestamp.getTime()"
            @close="removeToast"
        ></toast>
    </div>
</template>

<script>
import Toast from "./Toast";

export default {
    components: {
        Toast,
    },

    data() {
        return {
            toasts: this.$toast.state,
        };
    },

    methods: {
        removeToast(timestamp) {
            this.$toast.removeModal(timestamp);
        },
    },
};
</script>

<style lang="scss">
.toast-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100050;
    display: flex;
    flex-direction: column;
    pointer-events: none;
    margin: 8px;

    &.center {
        align-items: center;

        .alert--toast {
            transform: translateY(-110%);

            &.show {
                transform: translateY(0);
            }

            &.bounce-exit {
                height: 0;
                overflow: hidden;
                opacity: 0;
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
                transform: translateY(-1000px);
            }
        }
    }

    &.right {
        align-items: flex-end;

        .alert--toast {
            transform: translateX(110%);

            &.show {
                transform: translateX(0);
            }

            &.bounce-exit {
                height: 0;
                overflow: hidden;
                opacity: 0;
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
                transform: translate3d(0, -2000px, 0);
            }
        }
    }
    &.left {
        align-items: end;

        .alert--toast {
            transform: translateX(-1000px);

            &.show {
                transform: translateX(0);
            }


            &.bounce-exit {
                transform: translateX(0);
                // height: 0;
                overflow: hidden;
                opacity: 0;
                margin-top: 0;
                margin-bottom: 0;
                padding-top: 0;
                padding-bottom: 0;
                // transform: translateX(-1000px);
            }
        }


    }

    &.bottom {
        top: auto;
        bottom: 0;
    }

    .alert--toast {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        // flex-direction: row;
        // align-items: stretch;
        border-radius: 6px;
        padding: 8px;
        margin: 10px;
        -webkit-font-smoothing: antialiased;

        pointer-events: all;
        opacity: 0;
        transition: all 0.15s;
        // transform: translate3d(0, -100%, 0);

        background-color: #000;
        color: #fff;
        box-shadow: 0 1px 10px 0 rgb(0 0 0 / 10%), 0 2px 15px 0 rgb(0 0 0 / 5%);

        &.show {
            opacity: 1;
            // transform: translateZ(0);
        }
    }

    .alert--toast-light {
        background-color: #fff;
        color: #000;

        .alert--toast-btn-clear {
            border-left-color: rgba(0, 0, 0, 0.4);
            color: #000;
        }
    }
    .alert--toast-success {
        background-color: rgb(18, 128, 92);
        color: #fff;
    }
    .alert--toast-info {
        background-color: rgb(13, 102, 208);
        color: #fff;
    }
    .alert--toast-error {
        background-color: rgb(201, 37, 45);
        color: #fff;
    }

    .alert--toast-body {
        flex: 1 1 auto;
        align-self: center;
        margin-left: 12px;
        margin-right: 12px;
    }

    .alert--toast-body + .alert--toast-buttons {
        padding-left: 8px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
    }

    .alert--toast-btn-clear {
        border-radius: 100%;
        padding: 8px;
        margin: 0;
        border: none;

        background-color: rgba(0, 0, 0, 0.1);

        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .alert--toast-icon {
        display: inline-block;
        color: inherit;
        fill: currentColor;
        pointer-events: none;

        width: 18px;
        height: 18px;
    }
    .alert--toast-prefix {
        margin-left: 8px;
    }
}
</style>


