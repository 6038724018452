<template>

    <div
        role="alert"
        class="alert--toast"
        :class="[
            `alert--toast-${type}`,
            isShow && 'show',
            isExit && 'bounce-exit',
        ]"
    >
        <svg
            class="alert--toast-prefix alert--toast-icon"
            focusable="false"
            aria-label="Success"
            role="img"
            v-if="type == 'success'"
        >
            <path
                d="M9 1a8 8 0 1 0 8 8 8 8 0 0 0-8-8zm5.333 4.54l-6.324 8.13a.6.6 0 0 1-.437.23h-.037a.6.6 0 0 1-.425-.176l-3.893-3.9a.6.6 0 0 1 0-.849l.663-.663a.6.6 0 0 1 .848 0L7.4 10.991l5.256-6.754a.6.6 0 0 1 .843-.1l.728.566a.6.6 0 0 1 .106.837z"
            ></path>
        </svg>

        <div class="alert--toast-body">
            <div class="alert--toast-content">{{ text }}</div>
        </div>

        <div class="alert--toast-buttons" v-if="isClose">
            <button
                type="button"
                aria-label="Close"
                class="alert--toast-btn-clear"
                @click="close"
            >
                <svg
                    class="alert--toast-icon"
                    viewBox="0 0 24 24"
                    role="button"
                    aria-label="close"
                    tabindex="0"
                >
                    <path
                        d="M12 10.586l7.293-7.293 1.414 1.414L13.414 12l7.293 7.293-1.414 1.414L12 13.414l-7.293 7.293-1.414-1.414L10.586 12 3.293 4.707l1.414-1.414L12 10.586z"
                        fill="currentColor"
                    ></path>
                </svg>
            </button>
        </div>
    </div>

</template>

<script>
export default {
    props: {
        timestamp: {
            type: Date,
            default: () => new Date(),
        },
        done: {
            type: Function,
            default: function () {},
        },
        cancel: {
            type: Function,
            default: function () {},
        },
        overlap: {
            type: Boolean,
            default: false,
        },
        prev: {
            type: Object,
            default: null,
        },
        params: {
            type: [Object, Array, Function],
        },
        text: {
            type: String,
            default: "Successfully renamed asset",
        },
        type: {
            type: String,
            default: "light",
        },
        autoClose: {
            type: Boolean,
            default: true,
        },
        delay: {
            type: Number,
            default: 3800,
        },

        isClose: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            isActive: false,
            isShow: false,
            isExit: false,

            _autoClose: true,
        };
    },

    created() {
        this.isActive = true;

        if( !this.isClose && !this.autoClose ){
            this._autoClose = true
        }
        else{
            this._autoClose = this.autoClose
        }

        setTimeout(() => {
            this.isShow = true;


            if (this._autoClose) {
                setTimeout(() => {
                    this.close();
                }, this.delay || 1800);
            }
        }, 800);
    },

    methods: {
        close() {
            const vm = this;
            this.isShow = false;
            this.isExit = true;

            setTimeout(() => {
                this.isActive = false;

                vm.$emit("close", this.timestamp);
            }, 400);
        },
    },
};
</script>
