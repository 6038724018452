<template>
    <div class="modal-container" v-if="models.length>0">
        <modal-component v-for="modal in models" v-bind="modal" :key="modal.timestamp.getTime()" @close="removeModal"></modal-component>
    </div>
</template>


<script>
//
import ModalComponent from './Modal'

export default {

    components:{
        ModalComponent
    },

    props: {
        transitionName: {
            type: String,
            default: 'list'
        },
        transitionMode: {
            type: String,
            default: 'in-out'
        },
        overlap: {
            type: Boolean,
            default: false
        },
        component: {
            type: [Object, Function]
        },
    },

    data(){
        return{
            models: this.$modal.state
        }
    },

    created(){
        // console.log( 'models...', this.models, this.component );
    },

    methods: {
        removeModal(timestamp) {
            // console.log( 'methods .. remove' );
            this.$modal.removeModal(timestamp);
        }
    },
}
</script>
