require('./bootstrap');

import Vue from 'vue';
import Vuex from 'vuex';


import VueSweetalert2 from 'vue-sweetalert2';
import Modal from './components/Modal/index.js';
import VueToast from './plugins/VueToast';

Vue.use(Vuex);
Vue.use(Modal);
Vue.use(VueToast);
Vue.use(VueSweetalert2);

Vue.component('VueLightbox', require('./plugins/VueLightbox.vue').default);

const HeaderNav = () => import('./components/HeaderNav/index.vue')
const HomeBanner = () => import('./components/HomeBanner/index.vue')

const CourseVideo = () => import('./components/CourseVideo/index.vue')
const QuizPop = () => import('./components/QuizPop/index.vue')
const PostPoint = () => import('./components/PostPoint/index.vue')

// const TestPlyr = () => import('./test/TestPlyr.vue')

const MuHri = () => import('./components/MuHri/index.vue')

const StreamVideo = () => import('./components/StreamVideo/index.vue')

const HomeFeed = () => import('./components/HomeFeed/index.vue')
const UploadProfile = () => import('./components/UploadProfile/index.vue')

const store = new Vuex.Store({
    state: {
        count: 0,

        vModal: {
            isActive: false
        }
    }
});

// var MyPartial = Vue.extend({});

// window.partial = new MyPartial({

// })

// window.partial.$mount().$appendTo('body')

new Vue({
    el: '#app',

    store,

    components: {
        HeaderNav,
        HomeBanner,

        CourseVideo,
        QuizPop,
        PostPoint,

        MuHri,

        StreamVideo,

        HomeFeed,

        UploadProfile,
    },
});
