

import Toasts from './Toasts'

const ToastStore = {
    state: [],
    defaults: {
        overlap: false,
        prev: null,
        done: function () {},
        cancel: function () {},
        params: {},
    },
    current: null,
    setOptions(options) {
        this.defaults = Object.assign(this.defaults, options);
    },

    addModal(res) {
        res.timestamp = new Date();
        res.timestamp.setMilliseconds(
            res.timestamp.getMilliseconds() + this.state.length
        );

        res = Object.assign({}, this.defaults, res);

        this.current = res

        this.state.push(res);
    },

    removeModal(timestamp) {

        const indexToDelete = this.state.findIndex(n => n.timestamp === timestamp);
        if (indexToDelete !== -1) {

            if( this.current ){

                if( this.current.prev ){
                    this.current = this.current.prev
                    this.current.overlap = false;
                }
            }

            this.state.splice(indexToDelete, 1);
        }
    },

    show(text, type) {
        return new Promise((resolve, reject) => {

            let data = {};

            if(typeof text === 'object'){
                data = text;

                if( this.current ){
                    this.current.overlap = true;
                    data.prev = this.current;
                }

                if( data.done ){
                    data.done = resolve;
                }

                if( data.cancel ){
                    data.cancel = reject;
                }

            }
            else{
                data.text = text

                if( type ){
                    data.type = type
                }
            }

            this.addModal(data);
        });
    }
}

const ToastPlugin = {

    install(Vue, options) {
        let app = new Vue({
            data: {
                toastStore: ToastStore,
            },
        });

        Vue.prototype.$toast = app.toastStore;
        Vue.component("ComponentToast", Toasts);

        if (options) {
            ToastStore.setOptions(options);
        }
    },
};

export default ToastPlugin;
